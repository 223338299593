import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ROUTES } from 'src/config/constants';

const AuthGuard = ({ children }) => {
  const { user } = useSelector((state) => state.account);

  if (!user) {
    return <Redirect to={ROUTES.SIGN_IN} />;
  }

  return children;
};

export default AuthGuard;
