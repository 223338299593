import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Layout from 'src/layout/Layout/Layout';
import AuthGuard from 'src/layout/AuthGuard';
import SignInGuard from 'src/layout/SignInGuard';
import { ROUTES } from 'src/config/constants';

const routesConfig = [
  {
    path: '*',
    layout: Layout,
    routes: [
      {
        exact: true,
        path: ROUTES.FOUR_O_FOUR,
        component: lazy(() => import('src/pages/Error404/Error404')),
      },
      {
        exact: true,
        guard: SignInGuard,
        path: ROUTES.SIGN_IN,
        component: lazy(() => import('src/pages/SignIn/SignIn')),
      },
      {
        exact: true,
        guard: AuthGuard,
        path: ROUTES.ACCOUNT,
        component: lazy(() => import('src/pages/Account/Account')),
      },
      {
        exact: true,
        path: ROUTES.HOME,
        component: lazy(() => import('src/pages/Home/Home')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<div />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          route.func && route.func();

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
