import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './Auth.module.css';
import { checkSignIn } from '../../utils/firebase';

function Auth({ children }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  checkSignIn((user) => {
    dispatch({
      type: 'SET_USER',
      payload: user,
    });

    setLoading(false);
  });

  if (isLoading) {
    return <div className={styles.spinner}></div>;
  }

  return children;
}

export default Auth;
