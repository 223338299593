import Header from 'src/components/Header/Header';
import Footer from 'src/components/Footer/Footer';
import Auth from 'src/components/Auth/Auth';
import Routes from 'src/Routes';

function App() {
  return (
    <div className="app">
      <Header />
      <Auth>
        <Routes />
      </Auth>
      <Footer />
    </div>
  );
}

export default App;
