import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyBlwMki_5viXWr6y-8R4TUBRWjntgWROJk',
  authDomain: 'fotozmy.firebaseapp.com',
  projectId: 'fotozmy',
  storageBucket: 'fotozmy.appspot.com',
  messagingSenderId: '426692755460',
  appId: '1:426692755460:web:2898b17f65e78295543551',
  measurementId: 'G-VVT3CEGMFQ',
};

const RESET_PASSWORD = 'RESET_PASSWORD';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const GoogleProvider = new GoogleAuthProvider();
const FBProvider = new FacebookAuthProvider();

export const checkSignIn = (callback) => {
  onAuthStateChanged(auth, callback);
};

export const signInWIthEmail = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const signInWIthGoogle = () => {
  return signInWithPopup(auth, GoogleProvider);
};

export const signInWIthFacebook = () => {
  return signInWithPopup(auth, FBProvider);
};

export const signOutUser = () => {
  return auth.signOut();
};

export const resetPassword = (email) => {
  return sendPasswordResetEmail(auth, email);
};

export const getErrorMessage = ({ mode, code }) => {
  if (code === 'auth/user-not-found') {
    return mode === RESET_PASSWORD
      ? 'Email not found'
      : 'Invalid email or password';
  }
  return 'Error logging in. Please try again.';
};
