import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { signOutUser } from 'src/utils/firebase';
import styles from './Header.module.css';
import { ROUTES } from 'src/config/constants';

function Header() {
  const { user } = useSelector((state) => state.account);
  const history = useHistory();

  return (
    <header className={styles.header}>
      <div className="container">
        <div className={styles.headerInner}>
          <div className={styles.logo}>
            <Link to={ROUTES.HOME}>Fotoz</Link>
          </div>

          <nav className={styles.nav}>
            <ul>
              {user && (
                <>
                  <li>
                    <Link to={ROUTES.ACCOUNT}>Account</Link>
                  </li>
                  <li>
                    <a
                      href="/"
                      onClick={(event) => {
                        event.preventDefault();
                        signOutUser().then((result) => {
                          history.push(ROUTES.HOME);
                        });
                      }}
                    >
                      Sign Out
                    </a>
                  </li>
                </>
              )}
              {!user && (
                <li>
                  <Link to={ROUTES.SIGN_IN}>Sign in</Link>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
