import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ROUTES } from 'src/config/constants';

const SignInGuard = ({ children }) => {
  const { user } = useSelector((state) => state.account);

  if (user) {
    return <Redirect to={ROUTES.HOME} />;
  }

  return children;
};

export default SignInGuard;
